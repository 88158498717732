import React from "react";

const TableHeadings = (props) => {
  return (
    <thead>
      <tr class="text-nowrap">
        {props.headings.map((value) => (
          <th>{value}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeadings;
