import React, { useEffect, useState } from "react";
import AddCategoryForm from "./Components/AddCategoryForm";
import ListCategoryTable from "./Components/ListCategoryTable";
import API from "../../config";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AddCategorySchema } from "../../Admin/Schemas/TipCategory/index";
import Spinnerwhite from "../Spinners/Spinnerwhite";

const TipCategory = () => {
  const [categories, setCategories] = useState([]);
  const [allcategories, setAllcategories] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [categoryError, setCategoryError] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [CheckLoader, setCheckLoader] = useState(true);
  const [whiteSpinner, setWhiteSpinner] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingCategoryUUID, setEditingCategoryUUID] = useState(null);
  const [ApiUrl, setApiUrl] = useState("create");
  const [preview, SetPreview] = useState(null);

  const initialValues = {
    category_name: "",
    image: "",
    parent: "",
    status: "",
    subscription: "",
    UUID: "",
  };

  const params = {
    perPage: perPage,
    page: currentPage,
    search: search,
  };

  useEffect(() => {
    setValues(initialValues);
    const fetchCategory = async () => {
      setCheckLoader(true);
      await API.post("/admin/tip/category", params)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === false) {
              toast.error(response.data.data.message, {
                autoClose: 3000,
              });
            } else {
              // toast.success(response.data.data.message, {
              //   autoClose: 3000,
              // });
              setCheckLoader(false);
              setCategories(response.data.data.tips_categories);
              setPagination(response.data.data.paginations);
            }
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            toast.error("Server Error Please try again!", {
              autoClose: 3000,
            });
          }
          if (error.response) {
            toast.error(error.response.data.errors, {
              autoClose: 3000,
            });
          }
        });
    };
    const fetchAllCategories = async () => {
      await API.get("/admin/tip/all-category")
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === false) {
              toast.error(response.data.data.message, {
                autoClose: 3000,
              });
            } else {
              // toast.success(response.data.data.message, {
              //   autoClose: 3000,
              // });
              setAllcategories(response.data.data.tips_categories);
            }
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            toast.error("Server Error Please try again!", {
              autoClose: 3000,
            });
          }
          if (error.response) {
            toast.error(error.response.data.errors, {
              autoClose: 3000,
            });
          }
        });
    };
    fetchCategory();
    fetchAllCategories();
  }, [reloadData, perPage, currentPage, search]);

  // save new tip created
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: AddCategorySchema,
    onSubmit: async (values, action) => {
      setWhiteSpinner(true);
      setCategoryError("");
      await API.post("/admin/tip/category/" + ApiUrl, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === false) {
              setCategoryError(response.data.errors);
              toast.error(response.data.response.message, {
                autoClose: 3000,
              });
            } else {
              toast.success(response.data.response.message, {
                autoClose: 3000,
              });
              setWhiteSpinner(false);
              setValues(initialValues);
              resetForm();
              setReloadData(!reloadData);
              setEditing(false);
              SetPreview(null);
              setApiUrl("create");
            }
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_BAD_REQUEST") {
            toast.error(error.response.data.message, {
              autoClose: 3000,
            });
            setWhiteSpinner(false);
          }
          if (error.code === "ERR_NETWORK") {
            toast.error("Server Error Please try again!", {
              autoClose: 3000,
            });
          }
          if (error.response) {
            if (error.response.data.response.message) {
              toast.error(error.response.data.response.message, {
                autoClose: 3000,
              });
            }

            setWhiteSpinner(false);
            if (error.response.data.errors) {
              setCategoryError(error.response.data.errors);
            }
          }
        });
    },
  });

  // deleted the tip category
  const handleDelete = async (uuid) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this category"
    );

    if (userConfirmed) {
      setValues(initialValues);
      SetPreview(null);
      await API.get("/admin/tip/category/delete/" + uuid)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === false) {
              toast.error(response.data.response.message, {
                autoClose: 3000,
              });
            } else {
              toast.success(response.data.response.message, {
                autoClose: 3000,
              });
              setReloadData(!reloadData);
            }
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            toast.error("Server Error Please try again!", {
              autoClose: 3000,
            });
          }
          if (error.response) {
            toast.error(error.response.data.errors, {
              autoClose: 3000,
            });
          }
        });
    }
  };
  // handle edit parent category
  const handleEditParent = (UUId, ParentUUID = null) => {
    setValues(initialValues);

    let categoryToEdit = categories.find((category) => category.uuid === UUId);

    if (ParentUUID !== null) {
      let ParentData = categories.find(
        (category) => category.uuid === ParentUUID
      );
      categoryToEdit = ParentData.child.find((child) => child.uuid === UUId);
    }

    if (categoryToEdit) {
      setEditing(true);
      setEditingCategoryUUID(UUId);
      setApiUrl("update");
      SetPreview(null);

      // Populate the form with existing category data
      setValues({
        category_name: categoryToEdit.category_name,
        image: categoryToEdit.image, // You may want to handle the image separately based on your requirements
        parent: categoryToEdit.parent,
        status: categoryToEdit.status,
        subscription: categoryToEdit.subscription,
        UUID: categoryToEdit.uuid,
      });
    }
  };

  // handle search
  const handleSearch = (valueSearch) => {
    setSearch(valueSearch);
  };
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row gy-4">
          <AddCategoryForm
            allcategories={allcategories}
            categoryError={categoryError}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            whiteSpinner={whiteSpinner}
            editing={editing}
            preview={preview}
            SetPreview={SetPreview}
          />
          <ListCategoryTable
            categories={categories}
            handleDelete={handleDelete}
            setPerPage={setPerPage}
            pagination={pagination}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            CheckLoader={CheckLoader}
            handleEditParent={handleEditParent}
            valueSearch={search}
            handleSearch={handleSearch}
          />
        </div>
      </div>
    </>
  );
};

export default TipCategory;
