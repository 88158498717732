import React from "react";
import Spinnerwhite from "../Spinners/Spinnerwhite";

const SubmitButton = ({ value, whiteSpinner }) => {
  return (
    <button type="submit" class="btn btn-primary waves-effect waves-light">
      {whiteSpinner ? <Spinnerwhite /> : value}
    </button>
  );
};

export default SubmitButton;
