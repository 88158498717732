import React from "react";

const PerPage = (props) => {
  const { setPerPage } = props;
  return (
    <>
      <select
        name="per_page"
        id="per_page"
        className="form-select"
        style={{ width: "90px" }}
        onChange={(e) => {
          setPerPage(e.currentTarget.value);
        }}
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </>
  );
};

export default PerPage;
