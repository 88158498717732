import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
const AuthGuard = () => {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const navigate = useNavigate();
  if (token === undefined) {
    return navigate("/");
  }
};

export default AuthGuard;
