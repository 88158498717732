import React from "react";
import { TipCategory } from "../Lang/En";
const StatusSelect = (props) => {
  const { name, oldvalue, handleChange, handleBlur } = props;
  return (
    <>
      <select
        className="form-control"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="">Choose an option</option>
        <option value="Active" selected={oldvalue === TipCategory.Active}>
          {TipCategory.Active}
        </option>
        <option value="InActive" selected={oldvalue === TipCategory.InActive}>
          {TipCategory.InActive}
        </option>
      </select>
    </>
  );
};

export default StatusSelect;
