import React from "react";

const Search = (props) => {
  const { valueSearch, handleSearch } = props;

  return (
    <>
      <div className="col-3 card-header py-0">
        <input
          type="search"
          name="search"
          id="search"
          className="form-control"
          placeholder="Search"
          value={valueSearch}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
    </>
  );
};

export default Search;
