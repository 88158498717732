document.addEventListener("DOMContentLoaded", function () {
  var menuItems = document.querySelectorAll(".menu-item");

  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener("mouseenter", function () {
      this.classList.add("open");
      this.classList.add("bg-primary");
    });

    menuItem.addEventListener("mouseleave", function () {
      this.classList.remove("open");
      this.classList.remove("bg-primary");
    });
  });
});
