import React from "react";
import { TipCategory } from "../Lang/En";

const SubscriptionSelect = (props) => {
  const { name, oldvalue, handleChange, handleBlur } = props;
  return (
    <>
      <select
        className="form-control"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="">Choose an option</option>
        <option value="Free" selected={oldvalue === TipCategory.Free}>
          {TipCategory.Free}
        </option>
        <option value="Paid" selected={oldvalue === TipCategory.Paid}>
          {TipCategory.Paid}
        </option>
      </select>
    </>
  );
};

export default SubscriptionSelect;
