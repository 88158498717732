import React from "react";

const PaginationControlls = (props) => {
  const { pagination, setCurrentPage, currentPage } = props;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="my-2">
        <button
          className="btn btn-primary"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>

        <span className="mx-1">{`Page ${currentPage} of ${pagination.last_page}`}</span>

        <button
          className="btn btn-primary"
          disabled={currentPage === pagination.last_page}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default PaginationControlls;
