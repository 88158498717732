import { createContext, useState } from "react";
import AuthenticateRoutes from "./Admin/Routes/AuthenticateRoutes";
import UnAuthenticateRoutes from "./Admin/Routes/UnAuthenticateRoutes";
import Cookies from "universal-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Admin/Css/loader.css";
import "./App.css";
import { ConfigProvider } from "antd";

const UserContext = createContext();
function App() {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [user, setUser] = useState(() => {
    let userProfle = localStorage.getItem("hoaProfile");
    if (userProfle) {
      return JSON.parse(userProfle);
    }
    return null;
  });
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#666cff",
          },
        }}
      >
        <UserContext.Provider value={{ user, setUser }}>
          <ToastContainer />
          {user && token ? <AuthenticateRoutes /> : <UnAuthenticateRoutes />}
        </UserContext.Provider>
      </ConfigProvider>
    </>
  );
}
export { UserContext };

export default App;
