import React, { useEffect, useState } from "react";
import { Tip } from "../../Lang/En";
import {
  Button,
  Flex,
  Image,
  Popconfirm,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import API from "../../../config";
import { toast } from "react-toastify";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import Paragraph from "antd/es/skeleton/Paragraph";
const ListTipTable = (props) => {
  const { setEditing, setEditValues, reloadData, setReloadData } = props;

  const [tips, setTips] = useState([]);

  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);

  const params = {
    perPage: perPage,
    page: currentPage,
    search: search,
    status: filterStatus,
  };

  const confirm = async (uuid) => {
    // deleted the tip
    setEditing(true);
    setEditValues({});
    try {
      const response = await API.get("/admin/tip/delete/" + uuid);
      if (response.status === 200) {
        if (response.data.status === false) {
          toast.error(response.data.response.message, {
            autoClose: 3000,
          });
        } else {
          toast.success(response.data.response.message, {
            autoClose: 3000,
          });
          message.success("Tip Deleted");
          setReloadData(!reloadData);
        }
      }
    } catch (error) {
      toast.error("Try Again! Something bad happend", {
        autoClose: 3000,
      });
    }
  };

  const cancel = (e) => {
    message.error("Tip not deleted");
  };

  const handleEditTip = (uuid) => {
    setEditValues({});
    const tipToEdit = tips.find((tip) => tip.uuid === uuid);
    console.log("data ius here ", tipToEdit);
    if (tipToEdit) {
      setEditing(true);
      setEditValues({
        tip_name: tipToEdit.tip_name,
        tip_description: tipToEdit.tip_description,
        image: tipToEdit.image,
        category_id: tipToEdit.category_uuid,
        status: tipToEdit.status,
        subscription: tipToEdit.subscription,
        UUID: tipToEdit.uuid,
      });
    }
  };

  const columns = [
    {
      title: "Tip Name",
      dataIndex: "tip_name",
      className: "text-nowrap",
      key: "uuid",
      sorter: (a, b) => a.tip_name.localeCompare(b.tip_name),
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "uuid",
      render: (image) => <Image width={80} src={image} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "uuid",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "InActive",
          value: "InActive",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      filterSearch: true,
      width: "40%",
      render: (status) => (
        <Tag color={status === "Active" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "uuid",
      filters: [
        {
          text: "Free",
          value: "Free",
        },
        {
          text: "Paid",
          value: "Paid",
        },
      ],
      onFilter: (value, record) => record.subscription.startsWith(value),
      filterSearch: true,
      width: "40%",
      render: (subscription) => (
        <Tag color={subscription === "Free" ? "green" : "red"}>
          {subscription}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "uuid",
      render: (data) => (
        <Flex gap="small" horizontal>
          <Button
            onClick={() => handleEditTip(data.uuid)}
            size="small"
            type="primary"
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete the tip"
            description="Are you sure to delete this tip?"
            onConfirm={() => confirm(data.uuid)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    fetchTips();
  }, [reloadData, perPage, currentPage, search]);

  const fetchTips = async () => {
    setSpinner(true);
    try {
      const response = await API.post("/admin/tip", params);
      if (response.status === 200) {
        if (response.data.status === false) {
          toast.error(response.data.data.message, {
            autoClose: 3000,
          });
        } else {
          // toast.success(response.data.data.message, {
          //   autoClose: 3000,
          // });
          setTips(response.data.data.tips);
          setPagination(response.data.data.paginations);
          setSpinner(false);
        }
      }
    } catch (error) {
      toast.error("Try Again! Something bad happend", {
        autoClose: 3000,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPerPage(pagination.pageSize);
    setCurrentPage(pagination.current);
  };
  const onSearch = (search_value) => {
    setSearch(search_value);
  };

  return (
    <>
      <div className="col-md-8">
        <div className="card mb-4">
          <div className="card-header pb-0 d-flex justify-content-between">
            <h5 className="card-title m-0">{Tip.ListTip}</h5>
            <Search
              placeholder="Tip Search"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </div>

          <div className="card-body">
            <div class="table-responsive ">
              <Table
                columns={columns}
                dataSource={tips}
                rowKey="uuid"
                // scroll={{
                //   x: 1300,
                // }}
                pagination={{
                  total: pagination.total,
                  pageSize: perPage,
                  showSizeChanger: true, // Show items per page dropdown
                  pageSizeOptions: ["5", "10", "30", "60", "100"],
                }}
                loading={spinner}
                onChange={handleTableChange}
                expandable={{
                  expandedRowRender: (record) => (
                    <Typography.Paragraph className="text-break">
                      <Typography.Text>
                        {record.tip_description}
                      </Typography.Text>
                    </Typography.Paragraph>
                  ),
                  rowExpandable: (record) => record.uuid !== "Not Expandable",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTipTable;
