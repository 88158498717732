import { Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  BulbOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

const items = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: <DashboardOutlined />,
    path: "/dashboard",
  },
  {
    label: "Tips",
    key: "tips",
    icon: <BulbOutlined />,
    children: [
      {
        label: "Manage Tips",
        key: "manage_tips",
        icon: <BulbOutlined />,
        path: "/tip",
      },
      {
        type: "divider",
      },
      {
        label: "Manage Category",
        key: "manage_category",
        icon: <MenuUnfoldOutlined />,
        path: "/tip-category",
      },
    ],
  },
];
const Navbar = (props) => {
  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  return (
    <>
      <Menu
        mode={props.mode}
        // theme="dark"
        theme={props.mode === "inline" ? "light" : "dark"}
        onClick={onClick}
        selectedKeys={[current]}
        style={{
          paddingLeft: props.mode === "inline" ? "12px" : "100px",
        }}
        className={props.mode === "inline" ? "bg-primary-dark text-white" : ""}
      >
        {items.map((item) =>
          item.children ? (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) =>
                child.type === "divider" ? (
                  <Menu.Divider key={child.key} />
                ) : (
                  <Menu.Item key={child.key}>
                    <Link to={child.path}>{child.label}</Link>
                  </Menu.Item>
                )
              )}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path}>{item.label}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </>
  );
};

export default Navbar;
