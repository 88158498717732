import React, { useState } from "react";
import { TipCategory } from "../../Lang/En";
import Label from "../../FormFields/Label";
import SubmitButton from "../../FormFields/SubmitButton";
import Select from "../../FormFields/Select";
import Input from "../../FormFields/Input";
import File from "../../FormFields/File";
import StatusSelect from "../../FormFields/StatusSelect";
import SubscriptionSelect from "../../FormFields/SubscriptionSelect";

const AddCategoryForm = (props) => {
  const {
    allcategories,
    categoryError,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    whiteSpinner,
    editing,
    preview,
    SetPreview,
  } = props;

  return (
    <>
      <div className="col-md-4">
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="card-title m-0">
              {!editing
                ? TipCategory.AddTipCategory
                : TipCategory.UpdateTipCategory}
            </h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row mb-4 g-4">
                <div className="col-12 ">
                  <div className="form-floating form-floating-outline">
                    <Input
                      attributes={{
                        name: "category_name",
                        type: "text",
                        id: "category_name",
                        className: "form-control",
                        placeholder: TipCategory.CategoryName,
                        value: values.category_name,
                      }}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <Label
                      attributes={{
                        htmlFor: "category_name",
                        value: TipCategory.CategoryName,
                      }}
                    />
                    {errors.category_name && touched.category_name ? (
                      <p className="text-danger">{errors.category_name}</p>
                    ) : null}
                    {categoryError.category_name && (
                      <span className="text-danger">
                        {categoryError.category_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-floating form-floating-outline">
                    <File
                      attributes={{
                        name: "image",
                        type: "file",
                        id: "image",
                        className: "form-control",
                        placeholder: "File",
                      }}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      SetPreview={SetPreview}
                    />
                    <Label
                      attributes={{
                        htmlFor: "image",
                        value: TipCategory.Image,
                      }}
                    />
                    {values.UUID !== "" ? (
                      <img
                        src={values.image}
                        alt=""
                        style={{ width: "100px" }}
                        className="mt-2"
                      />
                    ) : (
                      ""
                    )}

                    {preview !== null ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="mt-1 rounded"
                        style={{ maxWidth: "100%", maxHeight: "160px" }}
                      />
                    ) : (
                      ""
                    )}
                    {errors.image && touched.image ? (
                      <p className="text-danger">{errors.image}</p>
                    ) : null}
                    {categoryError.image && (
                      <span className="text-danger">{categoryError.image}</span>
                    )}
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-floating form-floating-outline">
                    <Select
                      name="parent"
                      oldvalue={values.parent}
                      defaultOption="Parent"
                      allcategories={allcategories}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <Label
                      attributes={{
                        htmlFor: "parent",
                        value: TipCategory.Parent,
                      }}
                    />
                    {errors.parent && touched.parent ? (
                      <p className="text-danger">{errors.parent}</p>
                    ) : null}
                    {categoryError.parent && (
                      <span className="text-danger">
                        {categoryError.parent}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating form-floating-outline">
                    <StatusSelect
                      name="status"
                      oldvalue={values.status}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <Label
                      attributes={{
                        htmlFor: "status",
                        value: TipCategory.Status,
                      }}
                    />
                    {errors.status && touched.status ? (
                      <p className="text-danger">{errors.status}</p>
                    ) : null}
                    {categoryError.status && (
                      <span className="text-danger">
                        {categoryError.status}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-floating form-floating-outline">
                    <SubscriptionSelect
                      name="subscription"
                      oldvalue={values.subscription}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <Label
                      attributes={{
                        htmlFor: "subscription",
                        value: TipCategory.Subscription,
                      }}
                    />
                    {errors.subscription && touched.subscription ? (
                      <p className="text-danger">{errors.subscription}</p>
                    ) : null}
                    {categoryError.subscription && (
                      <span className="text-danger">
                        {categoryError.subscription}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <SubmitButton
                value={
                  editing
                    ? TipCategory.UpdateCategory
                    : TipCategory.CreateCategory
                }
                whiteSpinner={whiteSpinner}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryForm;
