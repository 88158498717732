import React, { useContext } from "react";
import { UserContext } from "../../App";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Header = () => {
  const cookies = new Cookies();
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    localStorage.removeItem("hoaProfile");
    setUser("");
    cookies.remove("token");
    toast.success("You have been Logged out.", {
      autoClose: 3000, // The notification will automatically close after 3 seconds
    });
    return navigate("/");
  };
  return (
    <>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-xxl">
          <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
            <Link to="/dashboard" className="app-brand-link gap-2">
              <span className="app-brand-logo demo"></span>
              <span className="app-brand-text demo menu-text fw-bold">Hoa</span>
            </Link>
          </div>

          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <Link className="nav-item nav-link px-0 me-xl-4" to="/">
              <span className="app-brand-text demo menu-text fw-bold">Hoa</span>
            </Link>
          </div>

          <div
            className="navbar-nav-right d-flex align-items-center"
            id="navbar-collapse"
          >
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  className="nav-link dropdown-toggle hide-arrow"
                  href="/"
                  data-bs-toggle="dropdown"
                >
                  <div className="avatar avatar-online">
                    <img
                      src="../../assets/img/avatars/1.png"
                      alt="logo"
                      className="w-px-40 h-auto rounded-circle"
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      className="dropdown-item"
                      href="pages-account-settings-account.html"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online">
                            <img
                              src="../../assets/img/avatars/1.png"
                              alt="logo"
                              className="w-px-40 h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-medium d-block">John Doe</span>
                          <small className="text-muted">Admin</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <a className="dropdown-item" href="pages-profile-user.html">
                      <i className="mdi mdi-account-outline me-2"></i>
                      <span className="align-middle">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="pages-account-settings-account.html"
                    >
                      <i className="mdi mdi-cog-outline me-2"></i>
                      <span className="align-middle">Settings</span>
                    </a>
                  </li>

                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      <i className="mdi mdi-logout me-2"></i>
                      <span className="align-middle">Log Out</span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
            <input
              type="text"
              className="form-control search-input border-0"
              placeholder="Search..."
              aria-label="Search..."
            />
            <i className="mdi mdi-close search-toggler cursor-pointer"></i>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
