import React from "react";

const Select = (props) => {
  const {
    name,
    oldvalue,
    defaultOption,
    allcategories,
    handleChange,
    handleBlur,
  } = props;
  return (
    <>
      <select
        className="form-control"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="">Choose an option</option>
        <option value="0" selected={oldvalue === "0"}>
          {defaultOption}
        </option>
        {allcategories.length > 0 ? (
          <>
            {allcategories.map((value, index) => (
              <option
                key={index}
                value={value.uuid}
                selected={oldvalue === value.uuid}
              >
                {value.category_name}
              </option>
            ))}
          </>
        ) : (
          <></>
        )}
      </select>
    </>
  );
};

export default Select;
