import React from "react";

const ActionsButtons = (props) => {
  const { uuid, parentUUID, handleDelete, handleEdit } = props;
  return (
    <>
      <div class="dropdown">
        <button
          type="button"
          className="btn p-0 dropdown-toggle hide-arrow"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-vertical"></i>
        </button>
        <div className="dropdown-menu ">
          <button
            className="dropdown-item waves-effect"
            onClick={() => handleEdit(uuid, parentUUID)}
          >
            <i className="mdi mdi-pencil-outline me-1"></i>
            Edit
          </button>
          <br />
          <button
            className="dropdown-item waves-effect"
            onClick={() => handleDelete(uuid)}
          >
            <i className="mdi mdi-trash-can-outline me-1"></i>
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default ActionsButtons;
