import React, { useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Header from "../../Components/Admin/Header";
import Login from "../Login/Login";
import Aside from "../../Components/Admin/Aside";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthGuard from "../Middleware/AuthGuard";
import NotFound from "../NotFound";
import TipCategory from "../Tip/TipCategory";
import Navbar from "../../Components/Admin/AntDesigns/Navbar";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Tip from "../Tip/Tip";

const AuthenticateRoutes = () => {
  const [openMenu, setopenMenu] = useState(false);
  return (
    <BrowserRouter>
      <AuthGuard />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="bg-primary p-3 text-end menuIcon">
                {
                  <MenuOutlined
                    style={{ fontSize: 27 }}
                    className="text-white"
                    onClick={() => {
                      setopenMenu(true);
                    }}
                  />
                }
              </div>
              <Drawer
                open={openMenu}
                placement="left"
                closable={true}
                className="bg-primary-dark"
                onClose={() => {
                  setopenMenu(false);
                }}
                style={{ marginTop: "64px" }}
              >
                <Navbar mode="inline" />
              </Drawer>
              <div className="menuWeb">
                <Navbar mode="horizontal" />
              </div>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/tip-category" element={<TipCategory />} />
                <Route path="/tip" element={<Tip />} />
                <Route path="/" element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AuthenticateRoutes;
