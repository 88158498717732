import React from "react";

const File = ({ attributes, handleChange, handleBlur, SetPreview }) => {
  return (
    <>
      <input
        type={attributes.type}
        name={attributes.name}
        id={attributes.id}
        className={attributes.className}
        placeholder={attributes.placeholder}
        value={attributes.value}
        onChange={(event) => {
          // Set the file in formik values directly
          handleChange({
            target: { name: "image", value: event.target.files[0] },
          });
          if (event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
              SetPreview(e.target.result);
            };

            reader.readAsDataURL(event.target.files[0]);
          }
        }}
        onBlur={handleBlur}
      />
    </>
  );
};

export default File;
