import React from "react";

const Input = ({ attributes, handleChange, handleBlur }) => {
  return (
    <>
      <input
        type={attributes.type}
        name={attributes.name}
        id={attributes.id}
        className={attributes.className}
        placeholder={attributes.placeholder}
        value={attributes.value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </>
  );
};

export default Input;
