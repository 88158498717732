import * as Yup from "yup";

export const AddCategorySchema = Yup.object({
  category_name: Yup.string().required("Please enter category name"),
  image: Yup.mixed().when("uuid", {
    is: (uuid) => uuid && uuid.trim() !== "", // Check if uuid is not empty
    then: Yup.mixed().required("Image is required"), // Image is required when uuid is not empty
  }),
  parent: Yup.string().required("Please select an option"),
  status: Yup.string().required("Please select an option"),
  subscription: Yup.string().required("Please select an option"),
});
