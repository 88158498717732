import React, { useState } from "react";
import AddTipForm from "./Components/AddTipForm";
import ListTipTable from "./Components/ListTipTable";

const Tip = () => {
  const [editing, setEditing] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [reloadData, setReloadData] = useState(false);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row gy-4">
          <AddTipForm
            editing={editing}
            editValues={editValues}
            reloadData={reloadData}
            setReloadData={setReloadData}
            setEditValues={setEditValues}
          />
          <ListTipTable
            setEditing={setEditing}
            setEditValues={setEditValues}
            reloadData={reloadData}
            setReloadData={setReloadData}
          />
        </div>
      </div>
    </>
  );
};

export default Tip;
