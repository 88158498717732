import React from "react";
import { TipCategory } from "../../Lang/En";
import TableHeadings from "./TableHeadings";
import { TipTableHeading } from "../../Lang/En";
import ActionsButtons from "../../FormFields/ActionsButtons";
import PerPage from "../../FormFields/PerPage";
import PaginationControlls from "../../FormFields/PaginationControlls";
import Loader from "../../Spinners/Loader";
import Search from "../../FormFields/Search";

const ListCategoryTable = (props) => {
  const Headings = [
    TipTableHeading.CategoryName,
    TipTableHeading.Image,
    TipTableHeading.Status,
    TipTableHeading.Subscription,
    TipTableHeading.Action,
  ];

  const {
    categories,
    handleDelete,
    setPerPage,
    pagination,
    setCurrentPage,
    currentPage,
    CheckLoader,
    handleEditParent,
    valueSearch,
    handleSearch,
  } = props;
  return (
    <>
      <div className="col-md-8">
        <div className="card mb-4">
          <div className="card-header pb-0 d-flex justify-content-between">
            <h5 className="card-title m-0">{TipCategory.ListCategory}</h5>
            <PerPage setPerPage={setPerPage} />
          </div>
          <Search valueSearch={valueSearch} handleSearch={handleSearch} />
          <div className="card-body">
            <div class="table-responsive text-nowrap">
              <table class="table">
                <TableHeadings headings={Headings} />
                <tbody class="table-border-bottom-0">
                  {CheckLoader === true ? <Loader /> : ""}

                  {categories.length > 0 ? (
                    <>
                      {categories.map((value, index) => (
                        <>
                          <tr
                            style={{ backgroundColor: "#f2f2f2" }}
                            key={index}
                          >
                            <td>{value.category_name}</td>
                            <td>
                              <img
                                src={value.image}
                                alt="category_image"
                                style={{ width: "60px" }}
                              />
                            </td>
                            <td>
                              <span
                                className={`badge rounded-pill bg-label-${
                                  value.status === "Active"
                                    ? "primary"
                                    : "warning"
                                } me-1`}
                              >
                                {value.status}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge rounded-pill bg-label-${
                                  value.subscription === "Paid"
                                    ? "danger"
                                    : "success"
                                } me-1`}
                              >
                                {value.subscription}
                              </span>
                            </td>
                            <td>
                              <ActionsButtons
                                uuid={value.uuid}
                                parentUUID={null}
                                handleDelete={handleDelete}
                                handleEdit={handleEditParent}
                              />
                            </td>
                          </tr>
                          {value.child.length > 0 ? (
                            <>
                              {value.child.map((childvalue, index) => (
                                <tr key={index}>
                                  <td>--{childvalue.category_name}</td>
                                  <td>
                                    <img
                                      src={childvalue.image}
                                      alt="category_image"
                                      style={{ width: "60px" }}
                                    />
                                  </td>
                                  <td>
                                    <span
                                      className={`badge rounded-pill bg-label-${
                                        value.status === "Active"
                                          ? "primary"
                                          : "warning"
                                      } me-1`}
                                    >
                                      {childvalue.status}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge rounded-pill bg-label-${
                                        value.status === "Paid"
                                          ? "danger"
                                          : "success"
                                      } me-1`}
                                    >
                                      {childvalue.subscription}
                                    </span>
                                  </td>
                                  <td>
                                    <ActionsButtons
                                      uuid={childvalue.uuid}
                                      parentUUID={value.uuid}
                                      handleDelete={handleDelete}
                                      handleEdit={handleEditParent}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Category found!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PaginationControlls
                pagination={pagination}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListCategoryTable;
