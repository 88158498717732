import React from "react";

const Spinnerwhite = () => {
  return (
    <>
      <div
        className="spinner-border text-white"
        role="status"
        style={{ width: "22px", height: "22px" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </>
  );
};

export default Spinnerwhite;
