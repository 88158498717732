import React, { useState, useEffect, useContext } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import API from "../../config";
import Input from "../FormFields/Input";
import Label from "../FormFields/Label";
import LogIn from "../Lang/En";
import Spinnerwhite from "../Spinners/Spinnerwhite";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { signUpSchema } from "../Schemas/Login";
const Login = () => {
  const { user, setUser } = useContext(UserContext);
  const [spinner, setSpinner] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const [loginError, setLoginError] = useState([]);
  const cookie = new Cookies();
  const token = cookie.get("token");
  useEffect(() => {
    if (user && token) {
      return navigate("/dashboard");
    }
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: async (values, action) => {
        setSpinner(true);
        setLoginError("");
        await API.post("/admin/login", values)
          .then(function (response) {
            if (response.status === 200) {
              if (response.data.status === false) {
                alert("200");
                setLoginError({ wrongCreadentials: response.data.message });
                toast.error(response.data.message, {
                  autoClose: 3000,
                });
                setSpinner(false);
              } else {
                toast.success("You have been logged In.", {
                  autoClose: 3000,
                });
                const jwt = response.data.data.token;
                cookies.set("token", jwt, { maxAge: 2 * 60 * 60 });
                localStorage.setItem(
                  "hoaProfile",
                  JSON.stringify(response.data.data)
                );
                setUser(response.data.data);
                return navigate("/dashboard");
              }
              setSpinner(false);
            }
          })
          .catch(function (error) {
            setSpinner(false);
            if (error.code === "ERR_NETWORK") {
              toast.error("Server Error Please try again!", {
                autoClose: 3000,
              });
            }
            if (error.code === "ERR_BAD_REQUEST") {
              toast.error(error.response.data.message, {
                autoClose: 3000,
              });
              setLoginError(error.response.data.message);
            }
            if (error.response) {
              if (error.response.data.errors) {
                setLoginError(error.response.data.errors);
              }
            }
          });
      },
    });

  return (
    <>
      <div className="position-relative">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card p-2">
              <div className="app-brand justify-content-center mt-5">
                <a href="index.html" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo"></span>
                  <span className="app-brand-text demo text-heading fw-bold">
                    {LogIn.title}
                  </span>
                </a>
              </div>
              <div className="card-body mt-2">
                <h4 className="mb-2"> {LogIn.welcome}</h4>
                <p className="mb-4">{LogIn.signInMessage}</p>
                {loginError.wrongCreadentials && (
                  <p className="text-danger text-center">
                    {loginError.wrongCreadentials}
                  </p>
                )}
                <form className="mb-3" onSubmit={handleSubmit}>
                  <div className="form-floating form-floating-outline mb-3">
                    <Input
                      attributes={{
                        name: "email",
                        type: "email",
                        id: "email",
                        className: "form-control",
                        placeholder: LogIn.emailPlaceholder,
                        value: values.email,
                      }}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <Label
                      attributes={{
                        htmlFor: "email",
                        value: LogIn.email,
                      }}
                    />
                    {errors.email && touched.email ? (
                      <p className="text-danger">{errors.email}</p>
                    ) : null}
                    {loginError.email && (
                      <span className="text-danger">{loginError.email}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="form-password-toggle">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <Input
                            attributes={{
                              name: "password",
                              type: "password",
                              id: "password",
                              className: "form-control",
                              placeholder: "******",
                              value: values.password,
                            }}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <Label
                            attributes={{
                              htmlFor: "password",
                              value: LogIn.password,
                            }}
                          />
                          {errors.password && touched.password ? (
                            <p className="text-danger">{errors.password}</p>
                          ) : null}
                          {loginError.password && (
                            <span className="text-danger">
                              {loginError.password}
                            </span>
                          )}
                        </div>
                        <span className="input-group-text cursor-pointer">
                          <i className="mdi mdi-eye-off-outline"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="form-check"></div>
                    <a
                      href="auth-forgot-password-basic.html"
                      className="float-end mb-1"
                    >
                      <span>{LogIn.forgetPassword}</span>
                    </a>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary d-grid w-100"
                      type="submit"
                    >
                      {spinner ? <Spinnerwhite /> : LogIn.signin}
                    </button>
                  </div>
                </form>

                <div className="divider my-4">
                  <div className="divider-text">{LogIn.or}</div>
                </div>
              </div>
            </div>
            <img
              alt="mask"
              src="../../assets/img/illustrations/auth-basic-login-mask-light.png"
              className="authentication-image d-none d-lg-block"
              data-app-light-img="illustrations/auth-basic-login-mask-light.png"
              data-app-dark-img="illustrations/auth-basic-login-mask-dark.png"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
