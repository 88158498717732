import React from "react";

const Label = ({ attributes }) => {
  return (
    <>
      <label htmlFor={attributes.htmlFor}>{attributes.value}</label>
    </>
  );
};

export default Label;
