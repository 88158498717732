const LogIn = {
  title: "Hoa",
  welcome: "Welcome to Hoa! 👋",
  signInMessage: "Sign-in to admin account and start the managing",
  forgetPassword: "Forgot Password?",
  signin: "Sign in",
  or: "or",
  email: "Email",
  password: "Password",
  emailPlaceholder: "Enter your email",
};
const TipCategory = {
  AddTipCategory: "Add Tip Category",
  UpdateTipCategory: "Update Tip Category",
  CategoryName: "Category Name",
  Image: "Upload Image",
  Status: "Status",
  Subscription: "Subscription",
  CreateCategory: "Create Category",
  UpdateCategory: "Update Category",
  ListCategory: "List Category",
  Paid: "Paid",
  Free: "Free",
  InActive: "InActive",
  Active: "Active",
  Parent: "Parent",
};
const TipTableHeading = {
  Image: "Image",
  CategoryName: "CategoryName",
  Status: "Status",
  Subscription: "Subscription",
  Action: "Action",
};

const Tip = {
  AddTip: "Add Tip",
  UpdateTip: "Update Tip",
  ListTip: "List Tip",
  TipName: "Tip Name",
  CreateTip: " Create Tip",
  TipDescription: " Tip Description",
  Image: "Image",
  TipCategory: "Tip Category",
  Status: "Status",
  SelectCategories: "Select Categories",
  Paid: "Paid",
  Free: "Free",
  InActive: "InActive",
  Active: "Active",
  Subscription: "Subscription",
};
export { TipCategory, TipTableHeading, Tip };
export default LogIn;
