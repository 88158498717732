import React, { useEffect, useState } from "react";
import { Tip } from "../../Lang/En";
import { Button, Form, Image, Input, Select, Spin, Upload } from "antd";
import { BulbOutlined, InboxOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import API from "../../../config";
import { toast } from "react-toastify";

const AddTipForm = (props) => {
  const [form] = Form.useForm();
  const { editing, editValues, reloadData, setReloadData, setEditValues } =
    props;

  const [allcategories, setAllcategories] = useState([]);
  const [newImageUploadCheck, setNewImageUploadCheck] = useState(true);
  const CATEGORIES = allcategories;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const filteredCategories = CATEGORIES.filter(
    (o) => !selectedCategories.includes(o)
  );
  const [ApiUrl, setApiUrl] = useState("create");
  const [spinner, setSpinner] = useState(false);
  const [tipError, setTipError] = useState([]);

  useEffect(() => {
    fetchAllCategories();

    if (editing === true) {
      setApiUrl("update");
      form.setFieldsValue({
        tip_name: editValues.tip_name,
        tip_description: editValues.tip_description,
        hidden_image: editValues.image,
        category_id: editValues.category_id,
        status: editValues.status,
        subscription: editValues.subscription,
        UUID: editValues.UUID,
      });
    }
  }, [reloadData, editing, editValues, newImageUploadCheck]);

  const fetchAllCategories = async () => {
    try {
      const response = await API.get("/admin/tip/child-category");
      if (response.status === 200) {
        if (response.data.status === false) {
          toast.error(response.data.data.message, {
            autoClose: 3000,
          });
        } else {
          // toast.success(response.data.data.message, {
          //   autoClose: 3000,
          // });
          setAllcategories(response.data.data.tips_categories);
        }
      }
    } catch (error) {
      toast.error("Try Again! Something bad happend", {
        autoClose: 3000,
      });
    }
  };

  const onFinish = async (values) => {
    const formData = new FormData();

    // Append other form fields to FormData
    formData.append("tip_name", values.tip_name);
    formData.append("tip_description", values.tip_description);
    formData.append("category_id", values.category_id);
    formData.append("status", values.status);
    formData.append("subscription", values.subscription);
    formData.append("UUID", values.UUID);

    // Append the image file to FormData
    if (values.image && values.image.file) {
      formData.append("image", values.image.file.originFileObj);
    } else {
      formData.append("image", values.hidden_image);
    }
    setSpinner(true);
    setTipError("");
    try {
      const response = await API.post("/admin/tip/" + ApiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        if (response.data.status === false) {
          setTipError(response.data.errors);
          toast.error(response.data.response.message, {
            autoClose: 3000,
          });
        } else {
          toast.success(response.data.response.message, {
            autoClose: 3000,
          });

          form.resetFields();
          setEditValues({});
          setSpinner(false);
          // setEditing(false);
          // SetPreview(null);
          setApiUrl("create");
          setReloadData(!reloadData);
        }
      }
    } catch (error) {
      toast.error("Try Again! Something bad happend", {
        autoClose: 3000,
      });
      setSpinner(false);
    }
  };

  const beforeUpload = () => {
    setNewImageUploadCheck(false);
  };
  return (
    <>
      <div className="col-md-4">
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="card-title m-0">
              {!editing ? Tip.AddTip : Tip.UpdateTip}
            </h5>
          </div>
          <div className="card-body">
            <Spin spinning={spinner}>
              <Form onFinish={onFinish} layout="vertical" form={form}>
                {/* Hidden Input */}
                <Form.Item
                  label="Hidden UUID"
                  name="UUID"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Hidden Image"
                  name="hidden_image"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="tip_name"
                  label={Tip.TipName}
                  rules={[
                    {
                      required: true,
                      message: "Tip name is required!",
                    },
                  ]}
                >
                  <Input
                    addonBefore={<BulbOutlined />}
                    maxLength={100}
                    showCount
                    placeholder={Tip.TipName}
                  />
                </Form.Item>
                <Form.Item
                  name="tip_description"
                  label={Tip.TipDescription}
                  rules={[
                    {
                      required: true,
                      message: "Tip Description is required!",
                    },
                  ]}
                >
                  <TextArea
                    showCount
                    maxLength={5000}
                    placeholder={Tip.TipDescription}
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                    autoSize
                  />
                </Form.Item>
                <Form.Item
                  name="image"
                  label={Tip.Image}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Image is required!",
                  //   },
                  // ]}
                >
                  <Upload.Dragger
                    multiple={false}
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    showUploadList={{
                      showRemoveIcon: false,
                      showPreviewIcon: false,
                    }}
                    accept=".png,.jpg,.jpeg"
                  >
                    <p className="ant-upload-drag-icon p-0">
                      <InboxOutlined style={{ fontSize: 20 }} />
                    </p>
                    <p className="ant-upload-text p-0">
                      Click or drag file to this area to upload
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                {!editing && !newImageUploadCheck ? (
                  ""
                ) : (
                  <Image
                    src={editValues.image}
                    alt=""
                    style={{ width: "100px" }}
                    className="mt-2"
                  />
                )}
                <Form.Item
                  name="category_id"
                  label={Tip.TipCategory}
                  rules={[
                    {
                      required: true,
                      message: "Tip Category is required!",
                    },
                  ]}
                >
                  <Select
                    // mode="multiple"
                    placeholder={Tip.SelectCategories}
                    value={selectedCategories}
                    onChange={setSelectedCategories}
                    style={{
                      width: "100%",
                    }}
                    options={filteredCategories.map((item) => ({
                      value: item.uuid, // or whatever property represents the value
                      label: item.category_name, // or whatever property represents the label
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  name="status"
                  label={Tip.Status}
                  rules={[
                    {
                      required: true,
                      message: "Tip Status is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Status"
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: Tip.Active,
                        label: Tip.Active,
                      },
                      {
                        value: Tip.InActive,
                        label: Tip.InActive,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="subscription"
                  label={Tip.Subscription}
                  rules={[
                    {
                      required: true,
                      message: "Subscription is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Subscription"
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: Tip.Free,
                        label: Tip.Free,
                      },
                      {
                        value: Tip.Paid,
                        label: Tip.Paid,
                      },
                    ]}
                  />
                </Form.Item>
                <Button type="primary" loading={spinner} htmlType="submit">
                  {!editing ? Tip.CreateTip : Tip.UpdateTip}
                </Button>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTipForm;
